export default () => ({
  promotionState: {
    promoHubVisible: false, // 活动和券弹窗状态
    promotionCouponMix: null, // 底部活动和券融合数据
    mixSelectedCartIds: [], // 底部活动弹窗勾选商品缓存
    bottomPromotionData: [], // 底部活动数据（旧版）
    bottomPromotionConfig: [], // 底部活动配置（旧版）
    integrityPromotionInfo: {}, // 所有活动信息
    sortDoubleFreeShippingPriorityInAbt: '', // 免邮轮播序号
    cartPromotionAnalysis: {}, // 活动埋点数据
    cartPopUpInfoData: {}, // 凑单数据
    promoHubOldVisible: false, // 旧的活动和券弹窗状态
  }
})
