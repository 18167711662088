import { useLureBubbleHistory } from 'public/src/pages/product_app/footer/cart/hooks/useLureBubbleHistory.js'
import { useLastLureBubble } from 'public/src/pages/product_app/footer/cart/hooks/useLastLureBubble.js'
import { detectUserInteraction, isTimestampWithinDuration } from './incentiveTools.js'

class CartCheckoutIncentive {
  constructor() {
    this.mode = '' // OFF | different | same
    this.status = 'notStarted'
    this.config = {
      countdownDuration: 10, // 倒计时时长
      noActDuration: 3, // 无操作时长
      disappearDuration: 3, // 弹窗展示时长
      intervals: 3, // 切换间隔时长
      displayNum: 3, // 显示数量
    }
    this.hooks = {}

    this.index = 0
    this.cartLureList = []
    this.blacklistCacheKeys = [] // 黑名单气泡 cacheKey
    this.vaildCartLureList = [] // 有效的购物车诱导
    this.displayedCartLureListMap = new Map() // 已展示的购物车诱导
    this.lastCartLure = null

    this.showTimer = null
    this.hideTimer = null
    this.lureBubbleHistory = useLureBubbleHistory()
    this.lastLureBubble = useLastLureBubble()
  }

  updateCartLureList(cartLureList = []) {
    if (!this.mode) return
    this.cartLureList = cartLureList?.filter?.(cartLure => cartLure.source === '1' || cartLure.source === '2') ?? []
    this.updateVaildCartLureList()
  }

  setBlacklistCacheKeys(cacheKeys) {
    this.blacklistCacheKeys.push(...cacheKeys)
    if (!this.mode) return
    this.updateVaildCartLureList()
  }

  rearrangeCartLureList(arr, type) {
    // 根据type找对应的对象索引
    const idIndex = arr.findIndex?.(item => item.uniqueType === type) ?? -1

    // 如果没有找到对应的type，返回原数组的拷贝
    if (idIndex === -1) {
      return [...arr]
    }

    // 创建新数组并将匹配type的对象放在最前面
    let newArr = [arr[idIndex]]

    // 如果匹配的对象是数组的最后一个元素，则直接将除了该对象外的其他元素放在其后
    if (idIndex === arr.length - 1) {
      newArr = newArr.concat(arr.slice(0, idIndex))
      return newArr
    }

    // 将匹配对象之后的元素添加到新数组
    newArr = newArr.concat(arr.slice(idIndex + 1))

    // 将匹配对象之前的元素添加到新数组尾部
    newArr = newArr.concat(arr.slice(0, idIndex))

    return newArr
  }

  updateVaildCartLureList() {
    let res = []
    const lureShowHistory = this.lureBubbleHistory.getLureBubbleHistory()
    const lastLureBubble = this.lastLureBubble.getLastLureBubble()
    const lureShowed = []
    if (this.mode === 'same' && lastLureBubble) {
      // 需要联动，不走缓存
      this.displayedCartLureListMap.clear()
      res = this.rearrangeCartLureList([...this.cartLureList], lastLureBubble.type)
    } else {
      this.cartLureList.forEach(item => {
        if (this.blacklistCacheKeys.includes(item.cacheKey)) return
        if (this.mode === 'different') {
          if (lureShowHistory.find(f => f.type === item.uniqueType)) {
            lureShowed.push(item)
            return
          }
        }
        res.push(item)
      })
    }
    if (this.mode === 'different') {
      const lureShowedSort = []
      lureShowHistory.forEach(f => {
        const target = lureShowed.find(d => d.uniqueType === f.type)
        if (target) lureShowedSort.push(target)
      })
      const len = this.config.displayNum - res.length
      if (len > 0) {
        res.push(...lureShowedSort.slice(0, len))
      }
    }
    this.vaildCartLureList = res
    this.callIsStop()
  }

  setDisplayedCartLureMap(cartLure) {
    this.displayedCartLureListMap.set(cartLure.type, cartLure)
    this.lureBubbleHistory.setLureBubbleHistory('cartPage', cartLure.uniqueType)
  }

  getMixDisplayNum() {
    return Math.min(this.config.displayNum, this.vaildCartLureList.length)
  }

  initialize(cartLureInfo, config) {
    if (typeof window === 'undefined') return
    if (!(this.status === 'notStarted' || this.status === 'stopped')) return
    this.mode = config?.mode || this.mode || 'OFF'
    if (cartLureInfo.config) this.config = cartLureInfo.config

    if (this.mode === 'different') {
      this.displayedCartLureListMap = new Map()
    }
    this.lastCartLure = null
    this.blacklistCacheKeys = []
    this.updateCartLureList(cartLureInfo.cartLureList)
    this.index = 0

    if (this.vaildCartLureList.length === 0) return

    // 如果所有气泡都展示完毕, 清空已展示的气泡
    if (!this.vaildCartLureList.find(cartLure => !this.displayedCartLureListMap.get(cartLure.type))) {
      this.displayedCartLureListMap.clear()
    }

    this.status = 'notStarted'

    let hasLimitCartLure = !!this.getLimitCartLure()
    if (hasLimitCartLure) {
      this.start()
    } else {
      detectUserInteraction({
        noInteractionTimeout: this.config.noActDuration || 3, // 单位为秒
        scrollThreshold: 2, // 屏幕高度的倍数
        onNoInteraction: () => {
          console.log('用户在首次进入3秒内没有交互。')
          this.start()
        },
        onScrollThresholdReached: () => {
          console.log('用户滚动超过了两屏。')
          this.start()
        }
      })
    }

  }

  getNextCartLure() {
    const next = this.vaildCartLureList.find(cartLure => !this.displayedCartLureListMap.get(cartLure.type))
    return next
  }

  async triggerShow({ isReset = false } = {}) {
    const isStop = this.callIsStop()
    if (isStop) return

    const cartLure = this.getNextCartLure()
    if (!cartLure) {
      this.stop()
      return
    }

    const res = await this.hooks.show?.({
      cartLure,
    })
    if (res || isReset) {
      this.lastCartLure = cartLure
      this.index++
      this.setDisplayedCartLureMap(cartLure)

      this.callIsStop()

      clearTimeout(this.hideTimer)
      this.hideTimer = setTimeout(() => {
        this.triggerHide()
      }, this.config.disappearDuration * 1000)
    } else {
      if (this.status === 'stopped' || this.status === 'paused') return
      this.showTimer = setTimeout(() => {
        if (this.status === 'stopped' || this.status === 'paused') return
        this.triggerShow()
      }, this.config.intervals * 1000)
    }
  }

  async triggerHide() {
    clearTimeout(this.hideTimer)
    this.hideTimer = null

    await this.hooks.hide?.()

    this.callIsStop()

    if (this.status === 'stopped' || this.status === 'paused') return
    this.showTimer = setTimeout(() => {
      if (this.status === 'stopped' || this.status === 'paused') return
      this.triggerShow()
    }, this.config.intervals * 1000)
  }

  callIsStop() {
    if (this.status === 'stopped') return true
    if (this.index >= this.getMixDisplayNum()) {
      this.stop()
      return true
    }
    return false
  }

  getLimitCartLure() {
    return this.vaildCartLureList.find(f => f.bubble.timestamp && isTimestampWithinDuration(f.bubble.timestamp * 1000, this.config.countdownDuration * 60 * 1000))
  }

  // 开始
  start({ isReset = false } = {}) {
    if (this.status !== 'notStarted') return
    this.status = 'running'

    this.triggerShow({ isReset })
  }

  // 暂停
  async pause() {
    this.hooks.hide?.()

    if (this.status !== 'running') return
    this.status = 'paused'

    await this.triggerHide()
  }

  // 重启
  async restart() {
    if (this.status !== 'paused') return
    this.status = 'running'

    await this.triggerHide()
  }

  // 结束
  stop({ hide = false } = {}) {
    if (hide) this.hooks.hide?.()

    this.status = 'stopped'

    // 清除定时器
    clearTimeout(this.showTimer)
    this.showTimer = null
    clearTimeout(this.hideTimer)
    this.hideTimer = null
  }

  // 强行重置
  reset() {
    if (this.status !== 'notStarted') {
      this.status = 'notStarted'
      clearTimeout(this.showTimer)
      this.showTimer = null
      clearTimeout(this.hideTimer)
      this.hideTimer = null
      this.index = 0
      this.displayedCartLureListMap.clear()
    }
  }

  setHooks(hooks) {
    this.hooks = {
      show: (...args) => {
        return hooks.show?.(...args)
      },
      hide: (...args) => {
        return hooks.hide?.(...args)
      },
    }
  }

  getIndex() {
    return this.index
  }
}

// 单例
const _cartCheckoutIncentive = typeof window !== 'undefined' ? window.__cartCheckoutIncentive__ || (window.__cartCheckoutIncentive__ = new CartCheckoutIncentive()) : {
  updateCartLureList: () => {},
  pause: () => {},
  restart: () => {},
}

export default _cartCheckoutIncentive
