import { stateFactory } from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import Vuex from 'vuex'
import Vue from 'vue'

export default {
  namespaced: true,
  state: stateFactory(),
  getters,
  mutations,
  actions
}

/**
 * For mini cart Drawer(普通非标车)
 * @param {*} moduleName 
 * 普通非标车mini_cart,主车cart_v2
 * @returns store
 */
export function createStore(moduleName = 'cart_v2') {
  Vue.use(Vuex)
  return new Vuex.Store({ modules: { [moduleName]: {
    namespaced: true,
    state: stateFactory(),
    getters,
    mutations,
    actions
  } } })
}
