import * as api from '../api'
import { setLocalStorage, getSessionStorage } from '@shein/common-function'
// import { carouselInfo, topRightsFuns, promotionCouponMix, topBannerInfo, appCccBannerConfigInfo, soldoutItemsMock, interceptionResultInfo, titleNavStyleFromCcc } from '../mock'
import { handleCartList, updateSessionOrderCache } from '../utils'
import paypalAction from './paypalAction' 
import analysisAction from './analysisAction' 
import promotionAction from './promotionAction' 
import filterAction from './filterAction'
import overLimitAction from './overLimitAction'
import recommendAction from './recommendAction'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'
import { abtservice } from 'public/src/services/abt'
import prerenderCheckout from 'public/src/pages/cart_v2/utils/prerender'
import { updateRootStateFreeShipping } from 'public/src/pages/cart_v2/utils/fetcher.js'
import checkoutIncentive from 'public/src/pages/cart_v2/components/cartCheckoutIncentive/checkoutIncentive.js'
import { handlerCouponSaveToast } from 'public/src/pages/cart_v2/utils/coupon.js'
import { POSKEYS } from 'public/src/pages/cart_v2/utils/constants.js'
import { getQueryString } from '@shein/common-function'

const actions = {
  ...overLimitAction,
  ...paypalAction,
  ...analysisAction,
  ...promotionAction,
  ...filterAction,
  ...recommendAction,
  async initSsrData({ dispatch, state, commit }) {
    let originCartInfo = state.originCartInfo || {}
    commit('resetLocals')
    commit('updateState', { key: 'auto_use_coupon', value: (state.cartAbtInfo.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? 1 : 0) })
    commit('updateState', { key: 'isPlatForm', value: originCartInfo?.isMultiMall == '1' ? true : false })
    // commit('updateState', { key: 'appCccBannerConfigInfo', value: appCccBannerConfigInfo })
    await dispatch('handleCartRes', { code: 0, info: originCartInfo, isSSR: true })
    if (typeof window !== 'undefined') {
      dispatch('initOverLimitState')
    }
  },
  handleCartRes({ commit, dispatch, getters, state }, res) {
    if(res.code == 0) {
      if(typeof window != 'undefined'){
        let lastCheckStockTime = Date.now()
        commit('updateState', { key: 'lastCheckStockTime', value: lastCheckStockTime })
      }
      let { mallCartInfo = {}, hasPlatformInMallTags = null } = res.info || {}
      commit('updateState', { key: 'cartSameLurePopupInfo', value: mallCartInfo?.cartPopUpInfoData?.cartLurePopupInfo?.cartSameLurePopupInfo })
      commit('updateState', { key: 'hasPlatformInMallTags', value: hasPlatformInMallTags })
      let mallCarts = mallCartInfo?.mallCarts || []
      let { cartList, mallShippingInfo, integrityPromotionInfo } = handleCartList(mallCarts)
      // cartList = cartList.concat(soldoutItemsMock)
      commit('updateState', { key: 'carts', value: cartList })
      commit('updateState', { key: 'cartIds', value: cartList.map(item => item.id) })
      commit('updateCartItemMap', { carts: cartList })
      commit('initLocalChecked')
      commit('updateState', { key: 'mallShippingInfo', value: mallShippingInfo })
      let soldoutItems = cartList.filter(item => item.isInvalid == '1' && !item.canChangeMallInfo && !item.canChangeGoodInfo)
      commit('updateState', { key: 'soldoutItems', value: soldoutItems })
      commit('updateState', { key: 'soldoutIds', value: soldoutItems.map(item => item.id) })
      commit('updateState', { key: 'mallCartList', value: mallCarts.filter((item) => item.type == 1) || [] })
      commit('updateCarouselState', { usefulLabels: mallCartInfo?.carouselInfo || [] })
      commit('updateState', { key: 'topRightsFuns', value: mallCartInfo?.topRightsFuns || {} })
      commit('updateState', { key: 'topBannerInfo', value: mallCartInfo?.topBannerInfo || {} })
      commit('updateState', { key: 'savedPrice', value: mallCartInfo?.savedPrice || {} })
      commit('updateState', { key: 'priceMode', value: mallCartInfo?.priceMode || {} })
      commit('updateState', { key: 'mallCartBiData', value: mallCartInfo?.mallCartBiData || {} })
      commit('updateShowNewGoodsRecommend', mallCartInfo?.newRecommendFlag === '1')
      // commit('updateState', { key: 'topBannerInfo', value: topBannerInfo })
      // commit('updateState', { key: 'titleNavStyleFromCcc', value: titleNavStyleFromCcc })
      commit('updatePromotionState', { 
        promotionCouponMix: mallCartInfo?.promotionCouponMix || null,
        bottomPromotionData: mallCartInfo?.bottomPromotionData || [],
        bottomPromotionConfig: mallCartInfo?.bottomPromotionConfig || {},
        sortDoubleFreeShippingPriorityInAbt: mallCartInfo?.sortDoubleFreeShippingPriorityInAbt,
        cartPopUpInfoData: mallCartInfo?.cartPopUpInfoData,
      })
      commit('updateCheckoutState', { 
        bnplCheckoutInfo: res.info.bnplCheckoutInfo,
        checkoutLabelList: mallCartInfo?.checkoutLabelList || [],
        cartLureInfo: mallCartInfo?.cartLureInfo || {},
        assetPopupInfo: mallCartInfo?.assetPopupInfo,
        overLimitTipOnBottom: mallCartInfo?.overLimitTipOnBottom || '',
        creditRewardPopupInfo: mallCartInfo?.creditRewardPopupInfo || null,
      })
      if(state.moduleName != 'mini_cart') {
        checkoutIncentive.updateCartLureList(mallCartInfo?.cartLureInfo?.cartLureList)
      }
      commit('updatePaypalState', { countryId: res.info.defaultAddress?.countryId || '', }) 
      commit('updateState', { key: 'defaultAddress', value: res.info?.defaultAddress || {} })
      commit('updateState', { key: 'batchDeleteStayPopUp', value: mallCartInfo?.batchDeleteStayPopUp || {} })
      commit('updateState', { key: 'cartConfigs', value: mallCartInfo?.cartConfigs || {} })
      dispatch('handleCartInfo', res)
      dispatch('handleFilterLabel', mallCartInfo?.filterLabel)
      if (typeof window !== 'undefined') { //不依赖ssr的逻辑才放在这里
        if(state.moduleName != 'mini_cart') {
          dispatch('getNewRecommendInfo')
          dispatch('handleChannelSessionParams')
        }
        dispatch('handleMallShippingAnalysisParam')
        dispatch('handlePromotionInfo', integrityPromotionInfo)
        let _commit = commit
        if(state.moduleName != 'mini_cart') {
          prerenderCheckout.switchTaskState(getters.needFlushPrerenderCache)
          prerenderCheckout.refreshCache(getters.paymentFrontPaymentCode)
        } else {
          _commit = window._gb_app_?.$store?.commit
        }
        // change root state
        _commit('changeCartInfo', {
          carts: cartList,
          sum: res.info?.cartSumQuantity || 0,
          info: { 
            carts: cartList,
            total_discount_price: res.info?.mallCartPriceData?.savedPrice?.price || { amount: 0 },
          },
          skipPrefetch: state.moduleName == 'cart_v2'
        }, {
          root: true,
        })
        updateRootStateFreeShipping( { cartSumQuantity: res.info?.cartSumQuantity || 0, auto_use_coupon: state.auto_use_coupon }, isFreeShipping => {
          _commit('changeFreeShippingState', { isFreeShipping }, { root: true })
          window?._gb_cart_tag_tips_?.update()
        })
        commit('updateState', { key: 'replayTrigger', value: state.replayTrigger+1 })
      }
    }
  },
  handleCartInfo({ commit }, res) {
    let cartInfo = { 
      effectiveProductLineSumQuantity: res.info?.effectiveProductLineSumQuantity || 0,
      allProductLineSumQuantity: res.info?.allProductLineSumQuantity || 0,
      cartSumQuantity: res.info?.cartSumQuantity || 0,
      taxInclusiveTip: res.info?.taxFarmingTips || '',
      promotionEventTrackingPoint: res.info?.promotionEventTrackingPoint || [],
      checkedInterceptTip: res.info?.checkedInterceptTip || '',
      withCouponTip: res.info?.withCouponTip || '',
      showPromotionDetailPopUp: res.info?.showPromotionDetailPopUp,
      promotionDetailPopUp: res.info?.promotionDetailPopUp,
      mallCartPriceData: res.info?.mallCartPriceData || {},
      cartInterceptions: res.info?.cartInterceptions || {},
      checkedCartSumQuantity: res.info?.checkedCartSumQuantity || 0,
      interceptResultInfo: res.info?.interceptResultInfo,
      auto_use_points_info: res.info?.auto_use_points_info || {},
      totalPrice: res.info?.totalPrice || {},
    }
    commit('updateState', { key: 'cartInfo', value: cartInfo })
  },
  async fetchCartIndex({ dispatch, commit, state }, otherParams) {
    if ( typeof window == 'undefined' ) return
    const reqParams = {
      auto_use_coupon: state.auto_use_coupon,
      mixSelectedCartIds: state.promotionState.mixSelectedCartIds,
      ...otherParams,
    }
    reqParams.holdTopFilterTagIds = state.filterState.holdTopFilterTagIds
    if (state.moduleName === 'mini_cart') {
      reqParams.callscene = 'shopBagCart'
    }
    const from = getQueryString({ key: 'from' })
    if (from === 'cart_floor') {
      Object.assign(reqParams, {
        lureScene: 'floor',
        showCartLureNum: getQueryString({ key: 'count' }),
        showCartLureOrder: getQueryString({ key: 'type' }),
      })
    }
    if (from === 'footer_cart') {
      Object.assign(reqParams, {
        topCartLureType: getQueryString({ key: 'topType' }),
        lureScene: 'lure',
      })
    }
    const res = await api.getCartIndex(reqParams)
    commit('updateState', { key: 'popupCartLure', value: res?.info?.mallCartInfo?.cartLureInfo?.popupCartLure || {} })
    dispatch('handleCartRes', res)
    if(res.code == 0 && reqParams?.coupon_code){
      handlerCouponSaveToast(res.info?.mallCartInfo?.couponAddToCartToastTip, res.info?.mallCartInfo?.mallCartBiData?.couponAddToCartToastType)
    }

    return res
  },
  async fetchModifyCartCheckStatus({ dispatch, state }, params) {
    if (state.filterState.selectFilterLabel?.filterTagId !== 'all') {
      params.filterTagId = state.filterState.selectFilterLabel?.filterTagId
    }
    params.holdTopFilterTagIds = state.filterState.holdTopFilterTagIds
    let res = await api.modifyCartCheckStatus({ ...params, auto_use_coupon: state.auto_use_coupon })
    dispatch('handleCartRes', res)
    dispatch('fetchCouponInfo')
    return res
  },
  async fetchCartDelete({ dispatch, state }, { params, events }) {
    const { onSuccess } = events || {}
    let res = await api.cartDelete({ ...params, auto_use_coupon: state.auto_use_coupon })
    if(res.code == 0) {
      onSuccess && await onSuccess()
    }
    dispatch('handleCartRes', res)
    dispatch('fetchCouponInfo')
    return res
  },
  async fetchCartUpdate({ dispatch, state }, params) {
    if (state.filterState.selectFilterLabel?.filterTagId !== 'all') {
      params.filterTagId = state.filterState.selectFilterLabel?.filterTagId
    }
    params.holdTopFilterTagIds = state.filterState.holdTopFilterTagIds
    let res = await api.cartUpdate({ ...params, auto_use_coupon: state.auto_use_coupon })
    dispatch('handleCartRes', res)
    dispatch('fetchCouponInfo')
    return res
  },
  async fetchCartUpdateAttr({ dispatch, state }, params) {
    const reqParams = {
      auto_use_coupon: state.auto_use_coupon,
      ...params
    }
    if (state.filterState.selectFilterLabel?.filterTagId !== 'all') {
      reqParams.filterTagId = state.filterState.selectFilterLabel.filterTagId
    }
    params.holdTopFilterTagIds = state.filterState.holdTopFilterTagIds
    let res = await api.cartUpdateAttr(reqParams)
    state?.monitorUserInteraction?.stop({ reason: 'edit goods properties' })
    dispatch('handleCartRes', res)
    dispatch('fetchCouponInfo')
    return res
  },
  async fetchCartBatchWish({ dispatch, state }, { params, events }) {
    const { onSuccess } = events || {}
    let res = await api.cartBatchWish({ ...params, auto_use_coupon: state.auto_use_coupon })
    if(res.code == 0) {
      onSuccess && await onSuccess()
      await dispatch('fetchCartIndex')
    }
    return res
  },
  async fetchCartBatchAdd({ dispatch }, params) {
    let res = await api.cartBatchAdd(params)
    if(res.code == 0) {
      dispatch('fetchCartIndex')
      dispatch('fetchCouponInfo')
    }
    return res
  },
  /**
   * 购物车登录
   * @param {String} type 登录类型
   * @param {Function} updateSingelDataCb 登录状态更新前需要单独更新的数据, 或需要执行的方法
   */
  async handleCartLogin({ commit, state, rootState, dispatch }, {
    type,
    updateSingelDataCb = () => {},
  }) {
    const result = {
      emptyCart: false,
      equalCart: false
    }
    const beforeLoginCartSumQuantity = state.cartInfo.cartSumQuantity

    let promotion_price
    if (Number(state.savedPrice?.amount) > 0) {
      promotion_price = state.savedPrice.amountWithSymbol
    }
    await api.cartLoginPromise({ type, config: {
      retainData: {
        // 空购物车 不免邮
        free_shipping: beforeLoginCartSumQuantity && rootState.cartInfo.isFreeShipping,
        promotion_price
      }
    } })
    try {
      await updateSingelDataCb()
    } catch ( error ) {
      console.error('updateSingelDataCb Error')
    }
    commit('updateState', { key: 'loginStatus', value: true })
    const [res, abtInfo] = await Promise.all([
      api.getCartIndex({ auto_use_coupon: state.auto_use_coupon }),
      api.getAbt(POSKEYS)
    ])
    commit('updateCartAbtInfo', abtInfo)
    await dispatch('handleCartRes', res)
    if ( res?.code == 0 ) {
      const afterLoginCartSumQuantity = res.info?.cartSumQuantity
      result.emptyCart = afterLoginCartSumQuantity == 0 || beforeLoginCartSumQuantity == 0
      result.equalCart = afterLoginCartSumQuantity == beforeLoginCartSumQuantity
    }

    if ( (typeof SaPageInfo !== 'undefined') && (typeof SaPageInfo.page_param == 'object') && !result.equalCart ) {
      SaPageInfo.page_param.is_combine = 0
      setLocalStorage({ key: 'is_combine', value: '0', expire: 1000 * 60 })
    }
    dispatch('fetchCouponInfo')

    return { result, res: res }
  },
  async fetchUnpaidOrdersInfo({ commit, state }) {
    if(state.orderState.unpaidOrderInfo) return
    if(!state.loginStatus) return
    let cachedOrderIdList = getSessionStorage('CART_CACHE_ORDER_ID_LIST') || []
    const res = await api.getCanceledOrderInfo(cachedOrderIdList)
    if (res.type == '0') {
      commit('updateOrderState', { unpaidOrderInfo: res })
    } else if (res.type == '1') {
      updateSessionOrderCache(cachedOrderIdList, res.billno)
      commit('updateOrderState', { canceledOrderInfo: res })
    }
  },
  checkSoldOut({ commit, state }, { outStockCarts = null }) {
    if ( outStockCarts ) {
      let outStockCartsId = outStockCarts.map(item => item.id)
      const soldoutCarts = state.carts.filter(item => outStockCartsId.indexOf(item.id) > -1)
      commit('updateState', { key: 'outStockCarts', value: soldoutCarts })
    } 
  },
  // 获取券数据
  async fetchCouponInfo({ commit, state }) {
    const res = await (state.loginStatus ? api.geCartCouponList() : Promise.resolve({}))
    if (res.code == '0') {
      commit('updateState', { key: 'couponInfo', value: res?.info || {} })
    }
  },
  async getUseBffApi({ commit }, keys) {
    const value = await isSwitchBffApiVersion(keys)
    commit('updateState', { key: 'useBffApi', value })
  },
  handleSaPageInfo({ state }, { SaPageInfo }) {
    const _SaPageInfo = SaPageInfo || window.SaPageInfo
    if (_SaPageInfo) {
      if (!_SaPageInfo.page_param) _SaPageInfo.page_param = {}
      // eslint-disable-next-line @shein-aidc/abt/abt
      _SaPageInfo.page_param.abtest = abtservice.getUserAbtResultForAnalysis({ abtInfo: state.cartAbtInfo }).sa
    }
  },
  // 离开购物车关闭所有抽屉
  closeAllDrawers({ commit }) {
    commit('updatePromotionState', { promoHubOldVisible: false, promoHubVisible: false })
    commit('changeAllDiscountDetailDrawerParams', { show: false })
    commit('changeNewGoodsRecommendParams', { show: false })
    commit('changeDiscountDetailDrawerParams', { show: false })
    commit('resetAppendageDrawerStatus', false)
    commit('updateState', { key: 'drawerCouponHelper', value: false })
  }
}
export default actions
